import React from 'react'
import axios from 'axios'

import {
  Grid,
  Icon,
  Divider,
  Image,
  Segment,
  Container,
  Button,
  Header
} from 'semantic-ui-react'

import ImageGallery from 'react-image-gallery'
// import { Divider as Dividerr } from '@spectrum-web-components/divider'
import 'react-image-gallery/styles/css/image-gallery.css'
import { withRouter } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js'
// import Slider from "react-slick";
const userLang = navigator.language || navigator.userLanguage

class Confirm extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      cartItem: [],
      Item: '',
      products: [],
      accessoryCart: '',
      buyProducts: [],
      buyCartInfo: '',
      rentProducts: [],
      rentCartInfo: '',
      identifier: '',
      password: '',
      buyEmpty: true,
      rentEmpty: true,
      loading: true,
      userID: null,
      address: '',
      price: '',
      payment: '',
      somethingWrong: false,
      userInfo: '',
      wechatpayProcessing: false,
      alipayProcessing: false
    }
    this.getAccessoriesCart = this.getAccessoriesCart.bind(this)
    this.wechatpay = this.wechatpay.bind(this)
    this.onHandleSubmit = this.onHandleSubmit.bind(this)
    this.getProductInBuyCart = this.getProductInBuyCart.bind(this)
    this.alipay = this.alipay.bind(this)
  }

  componentDidMount () {
    const identifier = localStorage.getItem('identifier')
    const token = localStorage.getItem('token')
    const log = localStorage.getItem('login')
    const self = this
    const loading = self.state.loading

    const orderidPosition = this.props.history.location.search.search('orderid')
    const orderid = this.props.history.location.search.substring(
      orderidPosition + 8
    )
    const sign = localStorage.getItem('sign')


    window.setTimeout(() => {
      if (loading === true) {
        this.setState({
          loading: false
        })
      }
    }, 1000)

    if (log === 'false') {
      self.setState({ login: false })
    } else {
      self.setState({ login: true })

      axios('https://sheyou-backend.herokuapp.com/users/me', {
        method: 'get',
        headers: {
          Authorization: 'Bearer ' + token
        }
      }).then(function (res) {
        const userInfo = [res]
        if (userInfo === null) {
          self.setState({
            userInfo: []
          })
        } else if (userInfo !== null) {
          self.setState({
            userInfo: userInfo,
            userID: userInfo[0].data.id,
            membership: userInfo[0].membership
          })
        } else {
          alert('Something went wrong, maybe log in again :(')
          localStorage.setItem('login', 'false')
        }
      })
    }

    axios('https://sheyou-backend.herokuapp.com/orders/' + orderid, {
      method: 'get',
      headers: {
        Authorization: 'Bearer ' + token
      }
    })
      .then(function (res) {
        console.log(res.data)

        if (res.status === 200) {
          const order = res.data.orders
          const orderP = res.data.orders.data
          const cart = orderP.clothing
          const accessoryCart = orderP.accessory

          self.setState({
            address: order.info,
            price: order.data.price,
            payment: res.data.payment,
            cart: cart,
            accessoryCart: accessoryCart
          })

          self.getProductInBuyCart()
          self.getAccessoriesCart()
        } else {
          alert('something went wrong :' + res)
        }
      })
      .catch(function (err) {
        alert('something went wrong: ' + err)
      })

    self.setState({
      identifier: identifier,
      token: token
    })

   
    if (sign !== null || sign !== undefined) {
      axios('https://sheyou-backend.herokuapp.com/pay/alipayTradeQuery', {
        method: 'post',
        data: {
          outTradeNo: 'sheyoufashion' + orderid,
          sign: sign
        },
        headers: {
          Authorization: 'Bearer ' + token
        }
      })
        .then(function (resQuery) {
          console.log(resQuery)
        })
        .catch(function (errQuery) {
          console.log(errQuery)
        })
    }
  }

  getProductInBuyCart () {
    const self = this
    // const cartIt=localStorage.getItem('buyItem')

    // const parsedCart = JSON.parse(cartIt)

    // if(cartIt===[]||cartIt===null){
    //     self.setState({buyEmpty:true})
    // }else{self.setState({buyEmpty:false})
    const userID = self.state.userID
    const token = self.state.token

    const cartItems = self.state.cart
    console.log(cartItems)

    self.setState({ buyProducts: [] })

    if (cartItems.length === undefined) {
      alert('something went wrong :(')
      axios('https://sheyou-backend.herokuapp.com/users/' + userID, {
        method: 'put',
        data: {
          cart: []
        },
        headers: {
          Authorization: 'Bearer ' + token
        }
      }).then(function (res) {
        console.log(res)
        if (res.status === 200) {
          alert('your cart has been cleared sorry')
        } else {
          alert('something went wrong again')
        }
        self.setState({
          loading: false
        })
      })
    } else {
      cartItems.forEach(function (cart) {
        console.log(cart)
        if (cart.type !== 'sale') {
          axios
            .get('https://sheyou-backend.herokuapp.com/products?id=' + cart.id)
            .then(function (res) {
              if (res.status === 200) {
                const productSelected = res.data[0]
                console.log(productSelected)
                if (self.state.buyProducts === '') {
                  var product = productSelected

                  self.setState({ buyCartInfo: cart })

                  product.quantity = self.state.buyCartInfo.number
                  product.colors[0] = self.state.buyCartInfo.colors
                  product.sizes[0] = self.state.buyCartInfo.sizes

                  const stringProduct = [product]
                  self.setState({ buyProducts: stringProduct })
                } else if (self.state.buyProducts !== '') {
                  product = productSelected

                  // parsedCart.filter(function(cartP){
                  // if(cartP.id===cart.id){
                  self.setState({ buyCartInfo: cart })
                  // }})
                  product.quantity = self.state.buyCartInfo.number
                  product.colors[0] = self.state.buyCartInfo.colors
                  product.sizes[0] = self.state.buyCartInfo.sizes

                  const stringProduct = product
                  product = self.state.buyProducts

                  product.push(stringProduct)
                  self.setState({ buyProducts: product })
                }
              } else {
                alert('something went wrong')
                localStorage.setItem('login', 'false')
              }

              self.setState({
                loading: false
              })
            })
        } else if (cart.type === 'sale') {
          axios
            .get(
              'https://sheyou-backend.herokuapp.com/second-hand-products?id=' +
                cart.id
            )
            .then(function (res) {
              if (res.status === 200) {
                const productSelected = res.data[0]

                if (self.state.buyProducts === '') {
                  var product = productSelected

                  self.setState({ buyCartInfo: cart })

                  product.quantity = self.state.buyCartInfo.number
                  product.colors[0] = self.state.buyCartInfo.colors
                  product.sizes[0] = self.state.buyCartInfo.sizes
                  product.type = 'sale'

                  const stringProduct = [product]
                  self.setState({ buyProducts: stringProduct })
                } else if (self.state.buyProducts !== '') {
                  product = productSelected

                  // parsedCart.filter(function(cartP){
                  // if(cartP.id===cart.id){
                  self.setState({ buyCartInfo: cart })
                  // }})
                  product.quantity = self.state.buyCartInfo.number
                  product.colors[0] = self.state.buyCartInfo.colors
                  product.sizes[0] = self.state.buyCartInfo.sizes
                  product.type = 'sale'

                  const stringProduct = product
                  product = self.state.buyProducts

                  product.push(stringProduct)
                  self.setState({ buyProducts: product })
                }
              } else {
                alert('something went wrong')
                localStorage.setItem('login', 'false')
              }
            })
        }
      })
    }
  }

  getAccessoriesCart () {
    const self = this
    const userID = self.state.userID
    const token = self.state.token

    const cartItems = self.state.accessoryCart

    self.setState({ buyProducts: [] })

    if (cartItems.length === undefined) {
      alert('something went wrong :(')
      axios('https://sheyou-backend.herokuapp.com/users/' + userID, {
        method: 'put',
        data: {
          accessoryCart: []
        },
        headers: {
          Authorization: 'Bearer ' + token
        }
      }).then(function (res) {
        console.log(res)
        if (res.status === 200) {
          alert('your accessories has been cleared sorry')
        } else {
          alert('something went wrong again')
        }
        self.setState({
          loading: false
        })
      })
    } else {
      cartItems.forEach(function (cart) {
        if (cart.type !== 'sale') {
          axios
            .get(
              'https://sheyou-backend.herokuapp.com/accessaries?id=' + cart.id
            )
            .then(function (res) {
              if (res.status === 200) {
                const productSelected = res.data[0]

                if (self.state.buyProducts === '') {
                  var product = productSelected

                  self.setState({ buyCartInfo: cart })

                  product.quantity = self.state.buyCartInfo.number
                  product.colors[0] = self.state.buyCartInfo.colors
                  product.sizes[0] = self.state.buyCartInfo.sizes

                  const stringProduct = [product]
                  self.setState({ buyProducts: stringProduct })
                } else if (self.state.buyProducts !== '') {
                  product = productSelected

                  // parsedCart.filter(function(cartP){
                  // if(cartP.id===cart.id){
                  self.setState({ buyCartInfo: cart })
                  // }})
                  product.quantity = self.state.buyCartInfo.number
                  product.colors[0] = self.state.buyCartInfo.colors
                  product.sizes[0] = self.state.buyCartInfo.sizes

                  const stringProduct = product
                  product = self.state.buyProducts

                  product.push(stringProduct)
                  self.setState({ buyProducts: product })
                }
              } else {
                alert('something went wrong')
                localStorage.setItem('login', 'false')
              }

              self.setState({
                loading: false
              })
            })
        } else if (cart.type === 'sale') {
          axios
            .get(
              'https://sheyou-backend.herokuapp.com/second-hand-products?id=' +
                cart.id
            )
            .then(function (res) {
              if (res.status === 200) {
                const productSelected = res.data[0]

                if (self.state.buyProducts === '') {
                  var product = productSelected

                  self.setState({ buyCartInfo: cart })

                  product.quantity = self.state.buyCartInfo.number
                  product.colors[0] = self.state.buyCartInfo.colors
                  product.sizes[0] = self.state.buyCartInfo.sizes

                  const stringProduct = [product]
                  self.setState({ buyProducts: stringProduct })
                } else if (self.state.buyProducts !== '') {
                  product = productSelected

                  // parsedCart.filter(function(cartP){
                  // if(cartP.id===cart.id){
                  self.setState({ buyCartInfo: cart })
                  // }})
                  product.quantity = self.state.buyCartInfo.number
                  product.colors[0] = self.state.buyCartInfo.colors
                  product.sizes[0] = self.state.buyCartInfo.sizes

                  const stringProduct = product
                  product = self.state.buyProducts

                  product.push(stringProduct)
                  self.setState({ buyProducts: product })
                }
              } else {
                alert('something went wrong')
                localStorage.setItem('login', 'false')
              }
            })
        }
      })
    }
  }

  onHandleSubmit (event) {
    const self = this

    self.props.history.push('/mypage')
  }

  wechatpay () {
    const self = this
    const orderidPosition = this.props.history.location.search.search('orderid')
    const orderId = this.props.history.location.search.substring(
      orderidPosition + 8
    )
    const token = localStorage.getItem('token')
    const userID = this.state.userID
    var price = this.state.price
    price = JSON.parse(price)

    self.setState({
      wechatpayProcessing: true
    })
    if (orderId === null || orderId === undefined) {
      self.setState({ somethingWrong: true })
    } else {
      axios('https://sheyou-backend.herokuapp.com/pay/wechatpay', {
        method: 'post',
        data: {
          out_trade_no: 'sheyoufashion' + orderId,
          description: 'user' + userID + "'s patment for order num" + orderId,
          amount: {
            total: price * 100,
            currency: 'USD'
          }
        },
        headers: {
          Authorization: 'Bearer ' + token
        }
      })
        .then(function (res) {
          console.log(res)
          self.setState({
            wechatpayProcessing: false
          })
          self.props.history.push('/wechatpay?code_url=' + res.data.code_url)
        })
        .catch(function (err) {
          console.log(err)
          self.setState({
            wechatpayProcessing: false
          })
        })
    }
  }

  alipay () {
    const self = this
    const orderidPosition = this.props.history.location.search.search('orderid')
    const orderId = this.props.history.location.search.substring(
      orderidPosition + 8
    )
    const token = localStorage.getItem('token')
    const userID = this.state.userID
    var price = this.state.price

    console.log(orderId, userID)
    self.setState({
      alipayProcessing: true
    })

    if (orderId === null || orderId === undefined) {
      self.setState({ somethingWrong: true })
    } else {
      axios('https://sheyou-backend.herokuapp.com/pay/alipay', {
        method: 'post',
        data: {
          outTradeNo: 'sheyoufashion' + orderId,
          productCode: 'FAST_INSTANT_TRADE_PAY',
          totalAmount: price,
          subject: '舍予时尚',
          body: 'user' + userID + "'s patment for order num" + orderId
        },
        headers: {
          Authorization: 'Bearer ' + token
        }
      })
        .then(function (res) {
          console.log(res)
          self.setState({
            alipayProcessing: false
          })

          axios('https://sheyou-backend.herokuapp.com/orders/' + orderId, {
            method: 'put',
            data: {
              payment: 'alipay',
              paid:true
            },
            headers: {
              Authorization: 'Bearer ' + token
            }
          })
            .then(function (resOrder) {
              console.log(resOrder)
              const signPosition = res.data.search('sign=')
              var sign = res.data.substring(signPosition + 5)

              localStorage.setItem('sign', sign)
              window.open(res.data)
            })
            .catch(function (errOrder) {
              self.setState({ somethingWentWrong: true })
              console.log(errOrder)
            })
        })
        .catch(function (err) {
          console.log(err)
          self.setState({
            alipayProcessing: false
          })
        })
    }
  }

  render () {
    const self = this
    console.log(self)
    var fee = self.state.price
    var order = self.state.address
    const address = order.detailAddress
    const area = order.city + ' ,' + order.country

    // const payment = self.state.payment
    const products = self.state.buyProducts

    const loading = self.state.loading
    var images = []

    const token = localStorage.getItem('token')
    const orderidPosition = self.props.history.location.search.search('orderid')
    const orderid = self.props.history.location.search.substring(
      orderidPosition + 8
    )

    return (
      <div>
        {/* <Nav /> */}

        {self.state.somethingWrong === true && (
          <Grid>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>

            <Grid.Row colums={2}>
              <Grid.Column width={7}></Grid.Column>
              <Grid.Column width={6}>
                <Header size='huge'>
                  <Icon name='warning sign' size='massive' color='yellow' />
                  Something Went Wrong
                </Header>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
          </Grid>
        )}

        {self.state.somethingWrong !== true && (
          <>
            {loading ? (
              <Grid>
                <Grid.Row></Grid.Row>
                <Grid.Row></Grid.Row>
                <Grid.Row></Grid.Row>
                <Grid.Row></Grid.Row>
                <Grid.Row></Grid.Row>
                <Grid.Row></Grid.Row>
                <Grid.Row></Grid.Row>
                <Grid.Row></Grid.Row>
                <Grid.Row></Grid.Row>

                <Grid.Row colums={2}>
                  <Grid.Column width={7}></Grid.Column>
                  <Grid.Column width={6}>
                    <Header size='huge'>
                      <Icon
                        loading
                        name='asterisk'
                        size='massive'
                        color='yellow'
                      />
                      Loading...
                    </Header>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row></Grid.Row>
                <Grid.Row></Grid.Row>
                <Grid.Row></Grid.Row>
                <Grid.Row></Grid.Row>
                <Grid.Row></Grid.Row>
                <Grid.Row></Grid.Row>
                <Grid.Row></Grid.Row>
                <Grid.Row></Grid.Row>
                <Grid.Row></Grid.Row>
              </Grid>
            ) : (
              <>
                <div className='bg_image'>
                  <br />
                  <br />
                  <Container>
                    <Segment>
                      <Grid container>
                        <Grid.Row columns={5} style={{ marginTop: 20 }}>
                          <Grid.Column width={2}></Grid.Column>
                          <Grid.Column width={2}>
                            <Icon size='big' name='dot circle' color='orange' />
                            <Header
                              as='h6'
                              textAlign='left'
                              style={{ padding: 0, marginTop: 7 }}
                            >
                              Adress
                            </Header>
                          </Grid.Column>

                          <Grid.Column width={8}>
                            <Divider />
                          </Grid.Column>

                          <Grid.Column width={2}>
                            <Icon
                              size='big'
                              name='dot circle outline'
                              color='grey'
                            />
                            <Header
                              as='h6'
                              textAlign='left'
                              style={{ padding: 0, marginTop: 7 }}
                            >
                              Confirm
                            </Header>
                          </Grid.Column>
                          <Grid.Column width={2}></Grid.Column>
                        </Grid.Row>

                        <Grid.Row centered>
                          <Grid.Column width={6}>
                            {products.length !== 0 &&
                              products.forEach(function (product) {
                                images.push({
                                  original:
                                    product.picture[0].formats.small.url,
                                  thumbnail:
                                    product.picture[0].formats.thumbnail.url
                                })
                              })}
                            <ImageGallery
                              items={images}
                              showFullscreenButton={false}
                              showPlayButton={false}
                              showBullets={false}
                              showIndex={true}
                              autoPlay={true}
                            />
                          </Grid.Column>
                        </Grid.Row>

                        <Grid.Row></Grid.Row>
                        <Grid.Row></Grid.Row>

                        <Grid.Row
                          columns={3}
                          style={{
                            marginBottom: 0,
                            marginTop: 2,
                            paddingBottom: 0
                          }}
                        >
                          <Grid.Column width={2}></Grid.Column>

                          <Grid.Column width={11}>
                            <Header
                              as='h5'
                              textAlign='left'
                              style={{ marginTop: 1 }}
                            >
                              Address
                            </Header>
                          </Grid.Column>

                          <Grid.Column width={2}>
                            <Icon
                              name='check circle'
                              size='large'
                              color='orange'
                            />
                          </Grid.Column>
                        </Grid.Row>

                        <Grid.Row columns={3}>
                          <Grid.Column width={2}></Grid.Column>

                          <Grid.Column width={10}>
                            <Header as='h5' textAlign='left' color='grey'>
                              {address}
                            </Header>
                          </Grid.Column>

                          <Grid.Column width={2}>
                            <Header as='h5' textAlign='right' color='grey'>
                              {' '}
                              {area}
                            </Header>
                          </Grid.Column>
                        </Grid.Row>

                        <Grid.Row>
                          <Grid.Column width={2}></Grid.Column>

                          <Grid.Column width={11}>
                            <Link to='/checkout'>
                              <Header as='h5' textAlign='left' color='orange'>
                                Change
                              </Header>
                            </Link>
                          </Grid.Column>
                        </Grid.Row>

                        <Grid.Row
                          columns={3}
                          style={{
                            marginBottom: 0,
                            marginTop: 2,
                            paddingBottom: 0
                          }}
                        >
                          <Grid.Column width={2}></Grid.Column>

                          <Grid.Column width={11}>
                            <Header
                              as='h5'
                              textAlign='left'
                              style={{ marginTop: 1 }}
                            >
                              Payment
                            </Header>
                          </Grid.Column>

                          <Grid.Column width={2}>
                            {/* <Icon name='check circle' size='large' color='orange' /> */}
                          </Grid.Column>
                        </Grid.Row>

                        <Grid.Row columns={3}>
                          <Grid.Column width={2}></Grid.Column>

                          <Grid.Column width={10}>
                            <Header as='h5' textAlign='left' color='grey'>
                              {' '}
                              You should pay
                            </Header>
                          </Grid.Column>

                          <Grid.Column width={2}>
                            <Header as='h5' textAlign='right' color='orange'>
                              {' '}
                              {fee} RMB
                            </Header>
                          </Grid.Column>
                        </Grid.Row>

                        {/* <Grid.Row>
                    <Grid.Column width={2}>
                    </Grid.Column>

                    <Grid.Column width={11}>
                      <Link to="/payment">
                        <Header as='h5' textAlign='left' color='orange' >Change</Header>
                      </Link>
                    </Grid.Column>

                  </Grid.Row> */}

                        <Grid.Row centered>
                          <Grid.Column
                            width={4}
                            className='verticalDivider'
                            style={{ marginRight: 50 }}
                          >
                            <Grid>
                              <Grid.Row></Grid.Row>
                              <Grid.Row>
                                <Button
                                  style={{
                                    paddingLeft: 40,
                                    paddingRight: 40,
                                    width: 200
                                  }}
                                  loading={self.state.wechatpayProcessing}
                                  onClick={this.wechatpay}
                                >
                                  <Grid>
                                    <Grid.Row columns={2}>
                                      <Grid.Column width={6}>
                                        <Image
                                          src='https://res.cloudinary.com/sheyou/image/upload/v1641199060/wechatpay_4a5593c3f3.png?12222019.900000006'
                                          style={{ height: 20, width: 20 }}
                                        />
                                      </Grid.Column>
                                      <Grid.Column width={10}>
                                        <Header
                                          as='h5'
                                          style={{
                                            position: 'relative',
                                            top: 3
                                          }}
                                        >
                                          {userLang === 'zh-CN' && '微信支付'}
                                          {userLang !== 'zh-CN' && 'Wechatpay'}
                                        </Header>
                                      </Grid.Column>
                                    </Grid.Row>
                                  </Grid>
                                </Button>
                              </Grid.Row>
                              <Grid.Row>
                                <Button
                                  style={{
                                    paddingLeft: 40,
                                    paddingRight: 40,
                                    width: 200
                                  }}
                                  loading={self.state.alipayProcessing}
                                  onClick={this.alipay}
                                >
                                  <Grid>
                                    <Grid.Row columns={2}>
                                      <Grid.Column width={6}>
                                        <Image
                                          src='https://res.cloudinary.com/sheyou/image/upload/v1641199061/alipay_9a312bd4b6.png?12273824.400000006'
                                          style={{ height: 20, width: 20 }}
                                        />
                                      </Grid.Column>
                                      <Grid.Column width={10}>
                                        <Header
                                          as='h5'
                                          style={{
                                            position: 'relative',
                                            top: 3
                                          }}
                                        >
                                          {userLang === 'zh-CN' && '支付宝'}
                                          {userLang !== 'zh-CN' && 'Alipay'}
                                        </Header>
                                      </Grid.Column>
                                    </Grid.Row>
                                  </Grid>
                                </Button>
                              </Grid.Row>
                            </Grid>
                          </Grid.Column>

                          <Grid.Column width={4} style={{ marginLeft: 20 }}>
                            <Grid>
                              <Grid.Row></Grid.Row>
                              <Grid.Row>
                                {/* {
                                isPending ? (
                                  <Icon
                                    loading
                                    name='asterisk'
                                    size='massive'
                                    color='yellow'
                                  />
                                ) : null} */}

                                <PayPalScriptProvider
                                  options={{
                                    'client-id':
                                      'ASSrb-p3Kh8A1xag3fgiNrsSfPOhlIkj1Xe4uiCU8iEVF1bmnkNXPwk5GjeXVBOTDXQmZO4sJf5nwQMK'
                                  }}
                                >
                                  <PayPalButtons
                                    createOrder={(data, actions) => {
                                      console.log(data, actions)

                                      return actions.order
                                        .create({
                                          purchase_units: [
                                            {
                                              amount: {
                                                value: '1.99'
                                              }
                                            }
                                          ]
                                        })
                                        .then(orderId => {
                                          axios(
                                            'https://sheyou-backend.herokuapp.com/orders/' +
                                              orderid,
                                            {
                                              method: 'put',
                                              data: {
                                                payment:
                                                  'paypal:orderId' + orderId
                                              },
                                              headers: {
                                                Authorization: 'Bearer ' + token
                                              }
                                            }
                                          )
                                            .then(function (res) {
                                              console.log(res)
                                            })
                                            .catch(function (err) {
                                              console.log(err)
                                            })

                                          return orderId
                                        })
                                    }}
                                    onApprove={(data, actions) => {
                                      return actions.order
                                        .capture()
                                        .then(details => {
                                          console.log(data.actions)
                                          axios(
                                            'https://sheyou-backend.herokuapp.com/orders/' +
                                              orderid,
                                            {
                                              method: 'put',
                                              data: {
                                                paid: true
                                              },
                                              headers: {
                                                Authorization: 'Bearer ' + token
                                              }
                                            }
                                          )
                                            .then(function (res) {
                                              window.setTimeout(() => {
                                                self.props.history.push(
                                                  '/mypage'
                                                )
                                              })

                                              console.log(res)
                                            })
                                            .catch(function (err) {
                                              console.log(err)
                                            })
                                        })
                                    }}
                                  />
                                </PayPalScriptProvider>
                              </Grid.Row>
                            </Grid>
                          </Grid.Column>
                        </Grid.Row>
                      </Grid>
                    </Segment>
                  </Container>
                  <br />
                  <br />
                </div>
              </>
            )}
          </>
        )}
      </div>
    )
  }
}

export default withRouter(Confirm)
