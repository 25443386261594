import React from 'react'
import { Header } from 'semantic-ui-react'

const IntroTextPhone = () => (
  <div>
    {/* <Header as='h5' style={{ color: 'white' }}>
      {userLang === 'zh-CN' && '什么是共享衣橱？'}
      {userLang !== 'zh-CN' && 'What is Clothes Sharing?'}
    </Header> */}
    <Header style={{ color: 'white', fontSize: 30,paddingLeft:50,paddingRight:50}}>
      {/* {userLang === 'zh-CN' && 'SHEYOU'}
      {userLang !== 'zh-CN' && 'Rent + Buy'} */}
      SHEYOU
    </Header>

    {/* <Header as='h5' style={{ color: 'white' }}>
      Renting multiple clothes and return whenever you want, or try purchasing
      them with a discount, you can have more styles with less budget
    </Header> */}
  </div>
)

export default IntroTextPhone
