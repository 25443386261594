import React from 'react'
// import { Parallax, } from 'react-parallax';
import {
  Segment,
  Button,
  Container,
  Form,
  Grid
} from 'semantic-ui-react'
import {withRouter } from 'react-router-dom'

import IntlTelInput from 'react-intl-tel-input-ueat'
import 'react-intl-tel-input-ueat/dist/main.css'

class PersonalShopPop extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      shopName: '',
      email: '',
      phoneNumber: '',
      phoneVerification: ''
    }
    this.onHandleShopName = this.onHandleShopName.bind(this)
    this.onHandlePhoneNumber = this.onHandlePhoneNumber.bind(this)
    this.onHandlePhoneVerification = this.onHandlePhoneVerification.bind(this)
  }

  componentDidMount () {
    window.scrollTo(0, 0)
    const self = this
    window.onload = event => {
      self.setState({
        loading: false
      })
    }

    window.setTimeout(() => {
      if (self.state.loading === true) {
        this.setState({
          loading: false
        })
      }
    }, 700)
  }

  onHandleShopName (event) {
    this.setState({
      shopName: event.target.value
    })
  }
  onHandlePhoneNumber (event, data) {
    this.setState({
      phoneNumber: data
    })
  }
  onHandlePhoneVerification (event) {
    this.setState({
      phoneVerification: event.target.value
    })
  }

  onOpenShop () {}

  render () {
    console.log(this)
    return (
      <div style={{ paddingBottom: 0, marginBottom: 0 }}>
        {/* <Nav /> */}

        <Segment placeholder style={{ paddingTop: 0, marginTop: 0 }}>
         
          <br />
          <br />
          <br />
          <br />
          <Container>
            <Grid style={{ width: '100%' }}>
              <Grid.Row>
                <Grid.Column width={1}></Grid.Column>
                <Grid.Column width={14}>
                  <Form>
                    <Form.Input
                      onChange={this.onHandleShopName}
                      label='Shop Name'
                      placeholder='Shop Name'
                    />

                    {/* <Form.Input
                      onChange={this.onHandlePhoneNumber}
                      label='Phone Number'
                      placeholder='Phone Number'
                    /> */}
                    <Form.Field>
                      <label>Phone Number</label>
                      <IntlTelInput
                        preferredCountries={['cn']}
                        onPhoneNumberChange={this.onHandlePhoneNumber}
                        // onPhoneNumberBlur={onBlur()}
                      />{' '}
                      
                    </Form.Field>

                    <Form.Input
                      onChange={this.onHandlePhoneVerification}
                      label='Verification Code'
                      placeholder='Verification Code'
                    />
                  </Form>
                </Grid.Column>
                <Grid.Column width={1}></Grid.Column>

                <br />
                <br />
              </Grid.Row>

              <Grid.Row columns={3} centered>
                <Grid.Column width={10}>
                  <Button
                    onClick={this.onOpenShop}
                    content='Open Shop'
                    color='orange'
                    style={{ paddingLeft: 40, paddingRight: 40 }}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Container>
       
          <br />
          <br />
          <br />
          <br />
        </Segment>
      </div>
    )
  }
}

export default withRouter(PersonalShopPop)
