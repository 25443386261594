import React from 'react'
import { Parallax } from 'react-parallax'
import {
  Segment,
  Button,
  Divider,
  Form,
  Grid,
  Checkbox,
  Message
} from 'semantic-ui-react'
import { Link, withRouter } from 'react-router-dom'
import axios from 'axios'
// import Nav from '../nav/Nav'
import newOTP from 'otp-generators'
import emailjs from 'emailjs-com'

import Foot from '../foot/Foot'
import timestamp from 'time-stamp'
const userLang = navigator.language || navigator.userLanguage
var jwt = require('jsonwebtoken')

class LoginPage extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      username: '',
      password: '',
      products: [],
      token: '',
      rememberMe: false,
      loading: true,
      buttonLoading: false,
      loggedIn: false,
      somethingWrong: false
    }
    this.onHandleUsername = this.onHandleUsername.bind(this)
    this.onHandlePassword = this.onHandlePassword.bind(this)
    this.onHandleSubmit = this.onHandleSubmit.bind(this)
    this.onHandleRememberMe = this.onHandleRememberMe.bind(this)
  }

  componentDidMount () {
    window.scrollTo(0, 0)
    const self = this
    var loginFailure = self.props.loginFailure
    window.onload = event => {
      self.setState({
        loading: false
      })
    }
    const login = localStorage.getItem('login')
    const emailConfirmed = localStorage.getItem('emailConfirmed')
    if (login === 'true' && emailConfirmed === 'true') {
      self.props.history.push('/mypage')
    }

    if (loginFailure === 'Wrong identifier combination') {
      self.setState({
        somethingWrong: 'Wrong identifier & password combination'
      })
    } else if (loginFailure === 'An error occured') {
      self.setState({ somethingWrong: 'Something wrong occured!' })
    }

    window.setTimeout(() => {
      if (self.state.loading === true) {
        this.setState({
          loading: false
        })
      }
    }, 700)
    const remember = localStorage.getItem('rememberMe')
    var identifier = localStorage.getItem('identifier')
    var password = localStorage.getItem('password')
    if (remember === 'true')
      this.setState({
        rememberMe: remember,
        username: identifier,
        password: password
      })
  }

  onHandleUsername (event) {
    this.setState({
      username: event.target.value
    })
  }

  onHandlePassword (event) {
    this.setState({
      password: event.target.value
    })
  }

  onHandleSubmit (event) {
    event.preventDefault()
    const remember = this.state.rememberMe
    const self = this
    self.setState({
      buttonLoading: true
    })

    var identifier = this.state.username
    var password = this.state.password
    var rememberMe = this.state.rememberMe

    console.log(identifier, password)

    if (remember === 'true') {
      identifier = localStorage.getItem('identifier')
      password = localStorage.getItem('password')
      const identifierRetype = this.state.username
      const passwordRetype = this.state.password
      if (identifierRetype !== '' && passwordRetype !== '') {
        identifier = identifierRetype
        password = passwordRetype
      }
    } else {
      identifier = localStorage.getItem('identifier')
      password = localStorage.getItem('password')
      const rememberMe = localStorage.getItem('rememberMe')

      console.log(identifier, password)
      if (rememberMe !== null) {
        localStorage.removeItem('rememberMe')
      }

      if (
        (identifier === null && password === null) ||
        (identifier === '' && password === '')
      ) {
        identifier = this.state.username
        password = this.state.password
      }
    }

    axios
      .post('https://sheyou-backend.herokuapp.com/auth/local', {
        identifier: identifier,
        password: password
      })
      .then(function (res) {
        console.log(res)

        if (res.status === 200) {
          const emailConfirmed = res.data.user.confirmed
          self.setState({
            emailConfirmed: emailConfirmed
          })

          const token = res.data.jwt
          const email = res.data.user.email
          const username = res.data.user.username
          const id = res.data.user.id
          console.log(res.data)

          localStorage.setItem('emailConfirmed', emailConfirmed)
          localStorage.removeItem('toLog')
          const time = timestamp.utc('YYYY-MM-DD-HH')
          localStorage.setItem('loginTime', time)
          localStorage.setItem('token', token)
          localStorage.setItem('id', id)
          localStorage.setItem('identifier', identifier)
          localStorage.setItem('password', password)
          localStorage.setItem('rememberMe', rememberMe)

          if (!emailConfirmed) {
            const verificationCode = newOTP.generate(6, {
              alphabets: false,
              upperCase: false,
              specialChar: false
            })

            const token = jwt.sign(
              {
                data: verificationCode
              },
              'sheyoufashion',
              { expiresIn: '5min' }
            )

            emailjs
              .send(
                'service_orld5qb',
                'template_c1h9qsr',
                {
                  email: email,
                  reply_to: 'susanna.xinshu@gmail.com',
                  name: username,
                  verificationCode: verificationCode
                },
                'user_4rMSnDYBigUutDlR5RSib'
              )
              .then(
                function (response) {
                  console.log('SUCCESS!', response.status, response.text)
                  self.setState({
                    loading: false,
                    sentEmail: true
                  })

                  localStorage.setItem('identifier', identifier)
                  localStorage.setItem('verificationCode', token)
                  self.props.history.push('/emailConfirmation')
                },
                function (error) {
                  self.setState({
                    loading: false,
                    sentEmail: false,
                    somethingWrong:
                      'Email confirmation FAILED, please contact us...' +
                      error.text
                  })
                }
              )
          } else if (emailConfirmed) {
            localStorage.setItem('login', 'true')
            localStorage.setItem('log', 'true')

            self.setState({
              loggedIn: true,
              buttonLoading: false
            })
            self.props.history.push('/logging')
          }
        } else {
          // alert('something went wrong')
          self.setState({
            rememberMe: false,
            username: '',
            password: ''
          })
          localStorage.removeItem('toLog')
          self.props.history.push('/signin/failure')
        }
      })
      .catch(error => {
        localStorage.removeItem('toLog')
        self.setState({
          buttonLoading: false,
          rememberMe: false,
          username: '',
          password: ''
        })
        if (error.response.status === 400) {
          self.setState({
            buttonLoading: false,
            somethingWrong: 'Wrong identifier & password combination'
          })
          // alert("Wrong identifier combination")
          self.props.history.push('/signin/wrong')
          localStorage.removeItem('identifier')
          localStorage.removeItem('password')
          localStorage.removeItem('rememberMe')
        } else {
          self.setState({
            buttonLoading: false,
            somethingWrong: 'An error occurred:' + error.response.statusText
          })
          self.props.history.push('/signin/failure')
          // alert('An error occurred:', error.response.statusText)
        }
      })
  }

  onHandleResetPassword (event) {
    this.props.history.push('/forgetpassword')
  }

  onHandleRememberMe (event) {
    const self = this
    const remember = self.state.rememberMe
    if (remember !== 'true') {
      self.setState({ rememberMe: true })
    } else {
      self.setState({ rememberMe: false })
    }
  }

  render () {
    console.log(this.state)
    const remember = this.state.rememberMe
    const identifier = this.state.username
    const password = this.state.password
    // const toLog = localStorage.getItem('toLog')

    return (
      <div>
        {/* <Nav /> */}
        <Parallax className='gradient_limeade' strength={400}>
          <div>
            <Segment placeholder>
              {userLang === 'zh-CN' && (
                <Grid columns={2} centered>
                  <Grid.Column>
                    {this.state.loggedIn === true && (
                      <Message positive>
                        <Message.Header>成功</Message.Header>
                        <p>您已成功登陆</p>
                      </Message>
                    )}
                    {this.state.somethingWrong !== false && (
                      <Message negative>
                        <Message.Header>登陆失败</Message.Header>
                        <p>
                          {this.state.somethingWrong ===
                            'Wrong identifier & password combination' &&
                            '用户名和密码错误或者不匹配'}
                        </p>
                        <p>
                          {this.state.somethingWrong !==
                            'Wrong identifier & password combination' &&
                            '出现未知问题:('}
                        </p>
                      </Message>
                    )}

                    <Form>
                      {remember !== 'true' && (
                        <Form.Input
                          onChange={this.onHandleUsername}
                          icon='user'
                          iconPosition='left'
                          label='用户名 / 邮箱'
                          placeholder='Username / email'
                        />
                      )}

                      {remember === 'true' && (
                        <Form.Input
                          onChange={this.onHandleUsername}
                          icon='user'
                          iconPosition='left'
                          label='用户名 / 邮箱'
                          placeholder='Username / email'
                          value={identifier}
                        />
                      )}

                      {remember !== 'true' && (
                        <Form.Input
                          onChange={this.onHandlePassword}
                          icon='lock'
                          iconPosition='left'
                          label='密码'
                          type='password'
                        />
                      )}

                      {remember === 'true' && (
                        <Form.Input
                          onChange={this.onHandlePassword}
                          icon='lock'
                          iconPosition='left'
                          label='密码'
                          type='password'
                          value={password}
                        />
                      )}

                      <Form.Field>
                        {remember === 'true' && (
                          <Checkbox
                            defaultChecked
                            color='orange'
                            label='记住我'
                            onClick={this.onHandleRememberMe}
                          />
                        )}
                        {remember !== 'true' && (
                          <Checkbox
                            color='orange'
                            label='记住我'
                            onClick={this.onHandleRememberMe}
                          />
                        )}
                      </Form.Field>
                    </Form>
                    <br />
                    <br />
                    <Grid>
                      <Grid.Row columns={2}>
                        <Grid.Column>
                          <Button
                            loading={this.state.buttonLoading}
                            onClick={this.onHandleSubmit}
                            content='登录'
                            color='orange'
                          />
                        </Grid.Column>

                        <Grid.Column>
                          <Link to='/forgetpassword'>
                            <Button basic content='忘记密码' color='orange' />
                          </Link>
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </Grid.Column>

                  <Grid.Column verticalAlign='middle'>
                    <Link to='/signup'>
                      <Button
                        className='ml-auto'
                        content='注册'
                        icon='signup'
                        size='big'
                        color='orange'
                      />
                    </Link>
                  </Grid.Column>
                </Grid>
              )}

              {userLang !== 'zh-CN' && (
                <Grid columns={2} centered>
                  <Grid.Column>
                    {this.state.emailConfirmed === false && (
                      <Message warning>
                        <Message.Header>Emial not confirmed</Message.Header>
                        <p>You will get an email for confirmation</p>
                      </Message>
                    )}
                    {this.state.loggedIn === true && (
                      <Message positive>
                        <Message.Header>Success</Message.Header>
                        <p>Now You Are Logged In</p>
                      </Message>
                    )}
                    {this.state.somethingWrong !== false && (
                      <Message negative>
                        <Message.Header>Log In Failure</Message.Header>
                        <p>{this.state.somethingWrong}</p>
                        {localStorage.removeItem(identifier)}
                      </Message>
                    )}

                    <Form>
                      {remember !== 'true' && (
                        <Form.Input
                          onChange={this.onHandleUsername}
                          icon='user'
                          iconPosition='left'
                          label='Username / email'
                          placeholder='Username / email'
                        />
                      )}

                      {remember === 'true' && (
                        <Form.Input
                          onChange={this.onHandleUsername}
                          icon='user'
                          iconPosition='left'
                          label='Username / email'
                          placeholder='Username / email'
                          value={identifier}
                        />
                      )}

                      {remember !== 'true' && (
                        <Form.Input
                          onChange={this.onHandlePassword}
                          icon='lock'
                          iconPosition='left'
                          label='Password'
                          type='password'
                        />
                      )}

                      {remember === 'true' && (
                        <Form.Input
                          onChange={this.onHandlePassword}
                          icon='lock'
                          iconPosition='left'
                          label='Password'
                          type='password'
                          value={password}
                        />
                      )}

                      <Form.Field>
                        {remember === 'true' && (
                          <Checkbox
                            defaultChecked
                            color='orange'
                            label='Remember Me'
                            onClick={this.onHandleRememberMe}
                          />
                        )}
                        {remember !== 'true' && (
                          <Checkbox
                            color='orange'
                            label='Remember Me'
                            onClick={this.onHandleRememberMe}
                          />
                        )}
                      </Form.Field>
                    </Form>
                    <br />
                    <br />
                    <Grid>
                      <Grid.Row columns={2}>
                        <Grid.Column>
                          <Button
                            loading={this.state.buttonLoading}
                            onClick={this.onHandleSubmit}
                            content='Log In'
                            color='orange'
                          />
                        </Grid.Column>

                        <Grid.Column>
                          <Link to='/forgetpassword'>
                            <Button
                              basic
                              content='Forget Password'
                              color='orange'
                            />
                          </Link>
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </Grid.Column>

                  <Grid.Column verticalAlign='middle'>
                    <Link to='/signup'>
                      <Button
                        className='ml-auto'
                        content='Sign up'
                        icon='signup'
                        size='big'
                        color='orange'
                      />
                    </Link>
                  </Grid.Column>
                </Grid>
              )}

              <Divider vertical>Or</Divider>
            </Segment>
          </div>
          <div style={{ height: '500px' }} />
        </Parallax>

        <Foot />
      </div>
    )
  }
}

export default withRouter(LoginPage)
