import React from 'react'
import {
  Header,
  Grid,
  Button,
  Sidebar,
  Segment,
  Icon,
  Menu
} from 'semantic-ui-react'

import { withRouter } from 'react-router-dom'
import PersonalShopPop from './PersonalShopPop'
import EnterpriseShopPop from './EnterpriseShopPop'
// import axios from 'axios'
import { Parallax } from 'react-parallax'
import ToPrevious from '../RedirectPages/ToPrevious'
const userLang = navigator.language || navigator.userLanguage

class MerchantOpenShopPhone extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      personalVisible: false,
      companyVisible: false
    }
    this.personalVisible = this.personalVisible.bind(this)
    this.companyVisible = this.companyVisible.bind(this)
  }

  componentDidMount () {
    window.scrollTo(0, 0)
    const self = this
    window.onload = event => {
      self.setState({
        loading: false
      })
    }

    window.setTimeout(() => {
      if (self.state.loading === true) {
        this.setState({
          loading: false
        })
      }
    }, 700)
  }

  personalVisible () {
    const personalVisible = this.state.personalVisible

    if (personalVisible !== true) {
      this.setState({
        personalVisible: true
      })
    } else if (personalVisible === true) {
      this.setState({
        personalVisible: false
      })
    }
  }

  companyVisible () {
    const companyVisible = this.state.companyVisible
    console.log(companyVisible)

    if (companyVisible !== true) {
      this.setState({
        companyVisible: true
      })
    } else if (companyVisible === true) {
      this.setState({
        companyVisible: false
      })
    }
  }

  render () {
    const self = this
    return (
      <div>
        {/* <Nav /> */}

        <Parallax
          bgImage={
            'https://res.cloudinary.com/sheyou/image/upload/v1653724863/woman_in_red_hat_sunglasses_and_suit_of_90s_with_2022_01_12_03_20_40_utc_7cb0c2bedc.jpg?291171.1000000015'
          }
          style={{ width: '100%'}}
          bgImageAlt='merchant page image'
          strength={100}
        >
          <ToPrevious color=''/>
          <Segment
            basic
            inverted
            textAlign='center'
            style={{ backgroundColor: 'transparent' }}
          >
            
            <Grid centered columns={1} style={{ paddingTop: 20,paddingBottom:80 }}>
              
              <Grid.Row
              // style={{ backgroundColor: 'rgba(255,255,255,.8)' }}
              >
                
                <div>
                  {userLang === 'zh-CN' && (
                    <Header
                      textAlign='center'
                      style={{
                        fontSize: '60px',
                        fontWeight: '1000',
                        marginBottom: 0,
                        color: 'white'
                      }}
                    >
                      在舍予开店
                    </Header>
                  )}

                  {userLang !== 'zh-CN' && (
                    <Header
                      textAlign='center'
                      style={{
                        fontSize: '30px',
                        fontWeight: '3000',
                        marginBottom: 0,
                        color: 'white'
                      }}
                    >
                      Open Your Store
                    </Header>
                  )}
                </div>
              </Grid.Row>

              <Grid.Row centered columns={4} style={{ paddingTop: 350 }}>
                {/* <Link to='/individualMerchant'> */}
                <Button
                  onClick={self.personalVisible}
                  size='huge'
                  circular
                  color='yellow'
                >
                  {userLang === 'zh-CN' && '个人开店'}
                  {userLang !== 'zh-CN' && 'Personal Shop'}

                  <Icon
                    name='chevron right'
                    inverted
                    style={{ position: 'relative', top: 2 }}
                  />
                </Button>
                {/* </Link> */}
              </Grid.Row>
              <Grid.Row centered columns={4}>
                <Button
                  onClick={self.companyVisible}
                  size='huge'
                  circular
                  color='yellow'
                >
                  {userLang === 'zh-CN' && '企业开店'}
                  {userLang !== 'zh-CN' && 'Entreprise Shop'}

                  <Icon
                    name='chevron right'
                    inverted
                    style={{ position: 'relative', top: 2 }}
                  />
                </Button>
              </Grid.Row>
            </Grid>
          </Segment>
        </Parallax>

        <Parallax
          bgImage={
            'https://res.cloudinary.com/sheyou/image/upload/v1654174982/beautiful_women_posing_in_underwear_2021_09_01_23_55_47_utc_28ba089d41.jpg'
          }
          bgImageAlt='image'
          strength={100}
        >
          <Segment style={{ backgroundColor: 'rgba(0,0,0,0.3)' }}>
            <Grid style={{ paddingTop: 150, paddingBottom: 150 }}>
              <Grid.Row centered columns={3}>
                <Grid.Column width={12}>
                  <Header inverted style={{ fontSize: 20 }}>
                    {userLang === 'zh-CN' && '" 我没有衣服穿'}
                    {userLang !== 'zh-CN' && '" I Have Nothing To Wear'}
                  </Header>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row centered columns={3}>
                <Grid.Column width={12}>
                  <Header inverted as='h4'>
                    {userLang === 'zh-CN' &&
                      '研究表明，80%的女性平均仅将衣柜里的衣服穿五次'}
                    {userLang !== 'zh-CN' &&
                      'Research shows 80% of women wear the clothing in their closets an average of 5 times.'}
                  </Header>
                </Grid.Column>
              </Grid.Row>

              <Grid.Row centered columns={3}>
                <Grid.Column width={12}>
                  <Header as='h4' inverted>
                    {userLang === 'zh-CN' &&
                      '租用可以减少购买服装的高额支出。既能满足想穿新衣服的愿望，又能减轻整理衣橱和洗衣服造成的负担。'}
                    {userLang !== 'zh-CN' &&
                      'Renting can reduce high expenses on garments. Satisfy both the want to wear something new and release the stress of closet organizing and laundry.'}
                  </Header>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>
        </Parallax>

        <Parallax
          bgImage={
            'https://res.cloudinary.com/sheyou/image/upload/v1653733448/Screen_Shot_2022_05_28_at_6_23_46_PM_f3d08f7d9e.png?4640704.6000000015'
          }
          bgImageAlt='merchant page image'
          strength={100}
        >
          <Segment style={{ backgroundColor: 'rgba(0,0,0,0.3)' }}>
            <Grid style={{ paddingTop: 150, paddingBottom: 150 }}>
              <Grid.Row centered columns={3}>
                <Grid.Column width={12}>
                  <Header inverted style={{ fontSize: 20 }}>
                    {userLang === 'zh-CN' && ' 商户面对用户租借'}
                    {userLang !== 'zh-CN' && 'Business To Customer Renting'}
                  </Header>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row centered columns={3}>
                <Grid.Column width={12}>
                  <Header inverted as='h4'>
                    {userLang === 'zh-CN' &&
                      '舍予共享衣柜为个人品牌及商户提供平台以出租及销售他们的设计'}
                    {userLang !== 'zh-CN' &&
                      'SHEYOU Provide individual brands, designers and Merchants with a platform to rent out and sell their products.'}
                  </Header>
                </Grid.Column>
              </Grid.Row>

              <Grid.Row centered columns={3}>
                <Grid.Column width={12}>
                  <Header as='h4' inverted>
                    {userLang === 'zh-CN' &&
                      '我们与专业的洗衣服务机构合作，只要每件大约20元左右的成本让商户出租更加方便'}
                    {userLang !== 'zh-CN' &&
                      'We also cooperates with professional laundry services to make renting for merchants easy with around 20 CNY a piece cost.'}
                  </Header>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>
        </Parallax>

        <Parallax
          bgImage={
            'https://res.cloudinary.com/sheyou/image/upload/v1654418469/snapshot_of_colorful_summer_stylish_outfits_and_pa_2021_09_02_23_57_46_utc_fa385d6696.jpg'
          }
          bgImageAlt='merchant page image'
          strength={100}
        >
          <Segment style={{ backgroundColor: 'rgba(0,0,0,0.3)' }}>
            <Grid
              divided
              centered
              style={{
                paddingTop: 150,paddingBottom:150
              }}
            >
              <Header
                style={{
                  color: 'white',
                  fontSize: 20
                }}
              >
                {userLang === 'zh-CN' && '成为舍予商户'}
                {userLang !== 'zh-CN' && 'Become Merchant in SHEYOU'}
              </Header>
              <Grid.Row
                columns={4}
                style={{ paddingLeft: 10, paddingRight: 10 }}
              >
                <Grid.Column width={8}>
                  <div>
                    <>
                      <Header
                        style={{
                          color: 'white'
                        }}
                        as='h4'
                      >
                        {userLang === 'zh-CN' && '长期利润'}

                        {userLang !== 'zh-CN' && 'Higher Long Term Profit'}
                      </Header>
                      <Header.Subheader
                        style={{
                          color: 'white',
                          fontSize: 13
                        }}
                      >
                        {userLang === 'zh-CN' &&
                          '租借不会产生退货，租金加卖出的钱意味着更多的利润'}
                        {userLang !== 'zh-CN' &&
                          'Renting means no sales return, and rent plus purchase produce more profit in the long term.'}
                      </Header.Subheader>
                    </>
                  </div>
                </Grid.Column>
                <Grid.Column width={8}>
                  <div>
                    <>
                      <Header
                        style={{
                          color: 'white'
                        }}
                        as='h4'
                      >
                        {userLang === 'zh-CN' && '租借自由'}
                        {userLang !== 'zh-CN' && 'Freedom To Rent Out'}
                      </Header>

                      <Header.Subheader
                        style={{
                          color: 'white',
                          fontSize: 13
                        }}
                      >
                        {userLang === 'zh-CN' && '商户自定出租时间与租金'}
                        {userLang !== 'zh-CN' &&
                          'Merchants customzie their renting time and money charge'}
                      </Header.Subheader>
                    </>
                  </div>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row
                columns={4}
                style={{ paddingLeft: 10, paddingRight: 10 }}
              >
                <Grid.Column width={8}>
                  <div>
                    <>
                      <Header
                        style={{
                          color: 'white'
                        }}
                        as='h4'
                      >
                        {userLang === 'zh-CN' && '吸引用户'}
                        {userLang !== 'zh-CN' && 'Attract Customers'}
                      </Header>
                      <Header.Subheader
                        style={{
                          color: 'white',
                          fontSize: 13
                        }}
                      >
                        {userLang === 'zh-CN' &&
                          '租借对于用户更划算，也意味着更多笔交易'}
                        {userLang !== 'zh-CN' &&
                          'Renting is more cost effective for customers, which means more orders'}
                      </Header.Subheader>
                    </>
                  </div>
                </Grid.Column>
                <Grid.Column width={8}>
                  <div>
                    <>
                      <Header
                        style={{
                          color: 'white'
                        }}
                        as='h4'
                      >
                        {userLang === 'zh-CN' && '更少成本'}
                        {userLang !== 'zh-CN' && 'Lower Production Costs'}
                      </Header>
                      <Header.Subheader
                        style={{
                          color: 'white',
                          fontSize: 13
                        }}
                      >
                        {userLang === 'zh-CN' &&
                          '租借需要生产更少的商品，每个季节也有更少的剩余'}
                        {userLang !== 'zh-CN' &&
                          'Renting requires less production, which reduces out-of-season leftovers'}
                      </Header.Subheader>
                    </>
                  </div>
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <Grid>
              
            </Grid>
          </Segment>
        </Parallax>

        <Sidebar
          as={Menu}
          animation='overlay'
          // onHide={this.personalVisible}
          vertical
          visible={this.state.personalVisible === true}
          style={{ width: '40%' }}
          direction='bottom'
        >
          <Segment style={{ width: '100%', paddingBottom: 7, paddingTop: 7 }}>
            <Grid >
            <Grid.Row columns={4}>
                    <Grid.Column width={2}>
                      <Icon
                        name='close'
                        // size='small'
                        onClick={self.personalVisible}
                      />
                    </Grid.Column>
                    <Grid.Column width={12}>
                      <Header
                        as='h3'
                        color='teal'
                        textAlign='centered'
                        style={{
                          paddingBottom: 0,
                          marginBottom: 0,
                          fontWeight: 1000
                        }}
                      >
                         Open A Personal Shop
                      </Header>
                    </Grid.Column>
                  </Grid.Row>
             
            </Grid>

            {/* <Header as='h6'  textAlign='centered' style={{paddingTop:0,marginTop:0,color:'#384D5A'}}>
                             Next Generation Fashion Platform 
                         </Header> */}
          </Segment>

          <PersonalShopPop />
        </Sidebar>

        <Sidebar
          as={Menu}
          animation='overlay'
          // onHide={this.personalVisible}
          vertical
          style={{ width: '40%' }}
          visible={this.state.companyVisible === true}
          direction='bottom'
        >
          <Segment style={{ width: '100%', paddingBottom: 7, paddingTop: 7 }}>
            <Grid>
              <Grid.Row columns={2}>
                <Grid.Column width={2}>
                  <Icon
                    name='close'
                    // size='small'
                    onClick={self.companyVisible}
                    color='grey'
                   
                  />
                </Grid.Column>
                <Grid.Column width={12}>
                  <Grid>
                    <Grid.Row centered>
                      <Grid.Column>
                        <Header
                          as='h3'
                          color='teal'
                          textAlign='centered'
                       
                        >
                          Open An Enterprise Shop
                        </Header>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Grid.Column>
              </Grid.Row>
            </Grid>

            {/* <Header as='h6'  textAlign='centered' style={{paddingTop:0,marginTop:0,color:'#384D5A'}}>
                             Next Generation Fashion Platform 
                         </Header> */}
          </Segment>
          <EnterpriseShopPop />
        </Sidebar>
      </div>
    )
  }
}

export default withRouter(MerchantOpenShopPhone)
