import React from 'react'
import { Parallax } from 'react-parallax'
import { Grid } from 'semantic-ui-react'
import HomePageButton from './HomeSecButton'
import HomePageText from './HomeSecText'

class Parall extends React.Component {
  render () {
    const p = this.props
    const image = p.image
    const name = p.name

    return (
      <Parallax bgImage={image} bgImageAlt={name} strength={-100}>
        <div>
          {/* <Segment basic inverted textAlign='center' style={{ backgroundColor:'transparent'} }>
           */}
          <Grid centered style={{ paddingTop: 300 }}>
            <Grid.Column width={16}>
              <HomePageText />
            </Grid.Column>

            <Grid.Row>
              <Grid centered>
                <Grid.Column>
                  <HomePageButton />
                </Grid.Column>
              </Grid>
            </Grid.Row>
          </Grid>

          {/* </Segment> */}
        </div>

        <div style={{ height: '150px' }} />
      </Parallax>
    )
  }
}

export default Parall
