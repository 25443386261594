import React from 'react'
import {
  Header,
  Grid,
  Button,
  Sidebar,
  Segment,
  Icon,
  Menu
} from 'semantic-ui-react'
import { withRouter } from 'react-router-dom'
import PersonalShopPop from './PersonalShopPop'
import EnterpriseShopPop from './EnterpriseShopPop'
// import axios from 'axios'
import { Parallax } from 'react-parallax'
import Foot from '../foot/Foot'
const userLang = navigator.language || navigator.userLanguage

class MerchantOpenShop extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      personalVisible: false,
      companyVisible: false
    }
    this.personalVisible = this.personalVisible.bind(this)
    this.companyVisible = this.companyVisible.bind(this)
  }

  componentDidMount () {
    window.scrollTo(0, 0)
    const self = this
    window.onload = event => {
      self.setState({
        loading: false
      })
    }

    window.setTimeout(() => {
      if (self.state.loading === true) {
        this.setState({
          loading: false
        })
      }
    }, 700)
  }

  personalVisible () {
    const personalVisible = this.state.personalVisible

    if (personalVisible !== true) {
      this.setState({
        personalVisible: true
      })
    } else if (personalVisible === true) {
      this.setState({
        personalVisible: false
      })
    }
  }

  companyVisible () {
    const companyVisible = this.state.companyVisible
    console.log(companyVisible)

    if (companyVisible !== true) {
      this.setState({
        companyVisible: true
      })
    } else if (companyVisible === true) {
      this.setState({
        companyVisible: false
      })
    }
  }

  render () {
    const self = this
    return (
      <div>
        {/* <Nav /> */}
        <Grid stackable>
          <Grid.Row
            style={{
              paddingTop: 200,
              paddingBottom: 200,
              marginBottom: 0,
              marginTop: 14,
              width: '100%',
              backgroundImage:
                'url(https://res.cloudinary.com/sheyou/image/upload/v1653724863/woman_in_red_hat_sunglasses_and_suit_of_90s_with_2022_01_12_03_20_40_utc_7cb0c2bedc.jpg?291171.1000000015)',
              backgroundSize: 'cover',
              backgroundPosition: 'center'
            }}
          >
            <Segment
              basic
              inverted
              textAlign='center'
              style={{ backgroundColor: 'transparent' }}
            >
              <Grid stackable centered columns={1} style={{ paddingTop: 80 }}>
                <Grid.Column
                // style={{ backgroundColor: 'rgba(255,255,255,.8)' }}
                >
                  <div>
                    {userLang === 'zh-CN' && (
                      <Header
                        textAlign='center'
                        style={{
                          fontSize: '60px',
                          fontWeight: '1000',
                          marginBottom: 0,
                          color: 'white'
                        }}
                      >
                        在舍予开店
                      </Header>
                    )}

                    {userLang !== 'zh-CN' && (
                      <Header
                        textAlign='center'
                        style={{
                          fontSize: '60px',
                          fontWeight: '1000',
                          marginBottom: 0,
                          color: 'white'
                        }}
                      >
                        Open Your Store In SHEYOU
                      </Header>
                    )}
                  </div>
                </Grid.Column>

                <Grid.Row centered columns={4}>
                  <Grid.Column width={8} textAlign='center'>
                    {/* <Link to='/individualMerchant'> */}
                    <Button
                      onClick={self.personalVisible}
                      size='huge'
                      circular
                      color='yellow'
                      style={{
                        paddingLeft: 50,
                        paddingRight: 50,
                        width: 300,
                        margin: '0 auto'
                      }}
                    >
                      {userLang === 'zh-CN' && '个人开店'}
                      {userLang !== 'zh-CN' && 'Personal Shop'}

                      <Icon
                        name='chevron right'
                        inverted
                        style={{ position: 'relative', top: 2 }}
                      />
                    </Button>
                    {/* </Link> */}
                  </Grid.Column>

                  <Grid.Column width={8} textAlign='center'>
                    <Button
                      onClick={self.companyVisible}
                      size='huge'
                      circular
                      color='yellow'
                      style={{
                        paddingLeft: 60,
                        paddingRight: 60,
                        width: 300,
                        margin: '0 auto'
                      }}
                    >
                      {userLang === 'zh-CN' && '企业开店'}
                      {userLang !== 'zh-CN' && 'Entreprise Shop'}

                      <Icon
                        name='chevron right'
                        inverted
                        style={{ position: 'relative', top: 2 }}
                      />
                    </Button>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Segment>
          </Grid.Row>
          <Grid.Row
            style={{
              // paddingTop: 100,
              marginTop: 0,
              marginBottom: 0,
              paddingBottom: 0,
              backgroundColor: 'rgba(241,241,241)'
            }}
          >
            <Grid.Column
              width={9}
              style={{
                marginBottom: 0,
                backgroundImage:
                  'url(https://res.cloudinary.com/sheyou/image/upload/v1653730579/Screen_Shot_2022_05_28_at_5_35_56_PM_a977be6fbc.png?1774877.1000000015)',
                backgroundSize: 'cover'
              }}
            ></Grid.Column>
            <Grid.Column
              width={7}
              centered
              style={{
                backgroundColor: 'rgba(241,241,241)',
                marginBottom: 0,
                paddingBottom: 50,
                paddingTop: 50
              }}
            >
              <Header
                style={{
                  color: 'rgba(37,37,37)',
                  paddingTop: 50,
                  fontSize: 100
                }}
              >
                “
              </Header>
              <Header
                style={{
                  position: 'relative',
                  top: -30,
                  color: 'rgba(37,37,37)',
                  fontSize: 50,
                  textDecoration: 'underline'
                }}
              >
                {userLang === 'zh-CN' && '没有衣服穿'}
                {userLang !== 'zh-CN' && 'I Have Nothing To Wear'}
              </Header>
              <Header
                as='h3'
                style={{ color: 'rgba(37,37,37)', paddingRight: 100 }}
              >
                {userLang === 'zh-CN' &&
                  '研究表明，80%的女性平均仅将衣柜里的衣服穿五次'}
                {userLang !== 'zh-CN' &&
                  'Research shows 80% of women wear the clothing in their closets an average of 5 times.'}
              </Header>
              <Header
                as='h3'
                style={{
                  color: 'rgba(37,37,37)',
                  paddingRight: 100,
                  paddingBottom: 100
                }}
              >
                {userLang === 'zh-CN' &&
                  '租用可以减少购买服装的高额支出。既能满足想穿新衣服的愿望，又能减轻整理衣橱和洗衣服造成的负担。'}
                {userLang !== 'zh-CN' &&
                  'Renting can reduce high expenses on garments. Satisfy both the want to wear something new and release the stress of closet organizing and laundry.'}
              </Header>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row
            style={{
              paddingTop: 0,
              marginTop: 0,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              backgroundImage:
                'url(https://res.cloudinary.com/sheyou/image/upload/v1654416265/warehouse_workers_wrapping_garment_stock_in_plasti_2022_03_07_23_58_56_utc_acd787db77.jpg)'
            }}
          >
            <Segment style={{ backgroundColor: 'rgba(0,0,0,0.3)' }}>
              <Grid centered style={{ paddingTop: 100, paddingBottom: 300 }}>
                <Header
                  style={{
                    color: 'white',
                    fontSize: 50,
                    paddingBottom: 100
                  }}
                >
                  {userLang === 'zh-CN' && '商户面对用户租借'}
                  {userLang !== 'zh-CN' && 'Business To Customer Renting'}
                </Header>
                <Grid.Row columns={3}>
                  <Grid.Column width={4}></Grid.Column>
                  <Grid.Column width={8}>
                    <div>
                      {userLang === 'zh-CN' && (
                        <>
                          <Header
                            as='h3'
                            style={{
                              color: 'white'
                            }}
                          >
                            舍予共享衣柜为个人品牌及商户提供平台以出租及销售他们的设计
                          </Header>
                          <Header
                            as='h3'
                            style={{
                              color: 'white'
                            }}
                          >
                            我们与专业的洗衣服务机构合作，只要每件大约20元左右的成本让商户出租更加方便
                          </Header>
                        </>
                      )}

                      {userLang !== 'zh-CN' && (
                        <>
                          <Header
                            as='h3'
                            style={{
                              color: 'white'
                            }}
                          >
                            SHEYOU Provide individual brands, designers and
                            Merchants with a platform to rent out and sell their
                            products.
                          </Header>
                          <Header
                            as='h3'
                            style={{
                              color: 'white'
                            }}
                          >
                            We also cooperates with professional laundry
                            services to make renting for merchants easy with
                            around 20 CNY a piece cost.
                          </Header>
                        </>
                      )}
                    </div>
                  </Grid.Column>
                  <Grid.Column width={4}></Grid.Column>
                </Grid.Row>
              </Grid>
            </Segment>
          </Grid.Row>

          <Grid.Row
            style={{
              paddingTop: 0,
              paddingBottom: 300,
              marginTop: 0,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              backgroundImage:
                'url(https://res.cloudinary.com/sheyou/image/upload/v1654419339/smiling_asian_businesswoman_working_at_a_table_in_2022_02_02_03_47_55_utc_b10a057b58.jpg)'
            }}
          >
            <Segment
              // style={{backgroundColor:'rgba(255,255,255,.8)', padding:20}}
              style={{
                backgroundColor: 'rgba(0,0,0,0.3)',
                paddingLeft: 50,
                paddingRight: 50
              }}
            >
              <Grid
                stackable
                divided
                style={{
                  paddingTop: 150,
                  paddingBottom: 70
                }}
              >
                <Header
                  style={{
                    color: 'white',
                    // paddingLeft: 100,
                    fontSize: 60
                  }}
                >
                  {userLang === 'zh-CN' && '成为舍予商户'}
                  {userLang !== 'zh-CN' && 'Become Merchant in SHEYOU'}
                </Header>
                <Grid.Row columns={6}>
                  <Grid.Column width={4} textAlign='center'>
                    <div>
                      <>
                        <Header
                          style={{
                            color: 'white'
                          }}
                          size='large'
                        >
                          {userLang === 'zh-CN' && '长期利润'}

                          {userLang !== 'zh-CN' && 'Higher Long Term Profit'}
                        </Header>
                        <Header
                          size='small'
                          style={{
                            color: 'white'
                          }}
                        >
                          {userLang === 'zh-CN' &&
                            '租借不会产生退货，租金加卖出的钱意味着更多的利润'}
                          {userLang !== 'zh-CN' &&
                            'Renting means no sales return, and rent plus purchase produce more profit in the long term.'}
                        </Header>
                      </>
                    </div>
                  </Grid.Column>
                  <Grid.Column width={4} textAlign='center'>
                    <div>
                      <>
                        <Header
                          style={{
                            color: 'white'
                          }}
                          size='large'
                        >
                          {userLang === 'zh-CN' && '租借自由'}
                          {userLang !== 'zh-CN' && 'Freedom To Rent Out'}
                        </Header>
                        <Header
                          size='small'
                          style={{
                            color: 'white'
                          }}
                        >
                          {userLang === 'zh-CN' && '商户自定出租时间与租金'}
                          {userLang !== 'zh-CN' &&
                            'Merchants customzie their renting time and money charge'}
                        </Header>
                      </>
                    </div>
                  </Grid.Column>
                  <Grid.Column width={4} textAlign='center'>
                    <div>
                      <>
                        <Header
                          style={{
                            color: 'white'
                          }}
                          size='large'
                        >
                          {userLang === 'zh-CN' && '吸引用户'}
                          {userLang !== 'zh-CN' && 'Attract Customers'}
                        </Header>
                        <Header
                          style={{
                            color: 'white'
                          }}
                          size='small'
                        >
                          {userLang === 'zh-CN' &&
                            '租借对于用户更划算，也意味着更多笔交易'}
                          {userLang !== 'zh-CN' &&
                            'Renting is more cost effective for customers, which means more orders'}
                        </Header>
                      </>
                    </div>
                  </Grid.Column>
                  <Grid.Column width={4} textAlign='center'>
                    <div>
                      <>
                        <Header
                          style={{
                            color: 'white'
                          }}
                          size='large'
                        >
                          {userLang === 'zh-CN' && '更少成本'}
                          {userLang !== 'zh-CN' && 'Lower Production Costs'}
                        </Header>
                        <Header
                          size='small'
                          style={{
                            color: 'white'
                          }}
                        >
                          {userLang === 'zh-CN' &&
                            '租借需要生产更少的商品，每个季节也有更少的剩余'}
                          {userLang !== 'zh-CN' &&
                            'Renting requires less production, which reduces out-of-season leftovers'}
                        </Header>
                      </>
                    </div>
                  </Grid.Column>

                  <Grid.Column width={1}></Grid.Column>
                </Grid.Row>
              </Grid>
              <Grid stackable style={{ paddingBottom: 100 }}>
                <Grid.Row columns={4}>
                  <Grid.Column width={8} textAlign='center'>
                    {/* <Link to='/individualMerchant'> */}
                    <Button
                      onClick={self.personalVisible}
                      size='huge'
                      circular
                      color='teal'
                      style={{
                        paddingLeft: 50,
                        paddingRight: 50,
                        width: 300
                      }}
                    >
                      {userLang === 'zh-CN' && '个人开店'}
                      {userLang !== 'zh-CN' && 'Personal Shop'}

                      <Icon
                        name='chevron right'
                        inverted
                        style={{ position: 'relative' }}
                      />
                    </Button>
                    {/* </Link> */}
                  </Grid.Column>

                  <Grid.Column width={8} textAlign='center'>
                    <Button
                      onClick={self.companyVisible}
                      size='huge'
                      circular
                      color='teal'
                      style={{
                        paddingLeft: 60,
                        paddingRight: 60,
                        width: 300
                      }}
                    >
                      {userLang === 'zh-CN' && '企业开店'}
                      {userLang !== 'zh-CN' && 'Entreprise Shop'}

                      <Icon
                        name='chevron right'
                        inverted
                        style={{ position: 'relative' }}
                      />
                    </Button>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Segment>
          </Grid.Row>
        </Grid>

        <Sidebar
          as={Menu}
          animation='overlay'
          // onHide={this.personalVisible}
          vertical
          visible={this.state.personalVisible === true}
          style={{ width: '40%' }}
          direction='right'
        >
          <Segment style={{ width: '100%', paddingBottom: 7, paddingTop: 7 }}>
            <Grid>
              <Grid.Row columns={4}>
                <Grid.Column width={1}>
                  <Icon
                    name='close'
                    size='small'
                    onClick={self.personalVisible}
                    color='grey'
                    style={{
                      fontSize: 20,
                      paddingTop: 7
                    }}
                  />
                </Grid.Column>
                <Grid.Column width={15}>
                  <Grid>
                    <Grid.Row centered>
                      <Grid.Column>
                        <Header
                          as='h4'
                          color='teal'
                          textAlign='centered'
                          style={{
                            paddingBottom: 0,
                            marginBottom: 0,
                            fontSize: 30
                          }}
                        >
                          Open A Personal Shop
                        </Header>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Grid.Column>
              </Grid.Row>
            </Grid>

            {/* <Header as='h6'  textAlign='centered' style={{paddingTop:0,marginTop:0,color:'#384D5A'}}>
                             Next Generation Fashion Platform 
                         </Header> */}
          </Segment>

          <PersonalShopPop />
        </Sidebar>

        <Sidebar
          as={Menu}
          animation='overlay'
          // onHide={this.personalVisible}
          vertical
          style={{ width: '40%' }}
          visible={this.state.companyVisible === true}
          direction='right'
        >
          <Segment style={{ width: '100%', paddingBottom: 7, paddingTop: 7 }}>
            <Grid>
              <Grid.Row columns={4}>
                <Grid.Column width={1}>
                  <Icon
                    name='close'
                    size='small'
                    onClick={self.companyVisible}
                    color='grey'
                    style={{
                      fontSize: 20,
                      paddingTop: 7
                    }}
                  />
                </Grid.Column>
                <Grid.Column width={15}>
                  <Grid>
                    <Grid.Row centered>
                      <Grid.Column>
                        <Header
                          as='h4'
                          color='teal'
                          textAlign='centered'
                          style={{
                            paddingBottom: 0,
                            marginBottom: 0,
                            fontSize: 30
                          }}
                        >
                          Open An Enterprise Shop
                        </Header>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Grid.Column>
              </Grid.Row>
            </Grid>

            {/* <Header as='h6'  textAlign='centered' style={{paddingTop:0,marginTop:0,color:'#384D5A'}}>
                             Next Generation Fashion Platform 
                         </Header> */}
          </Segment>
          <EnterpriseShopPop />
        </Sidebar>

        <Foot />
      </div>
    )
  }
}

export default withRouter(MerchantOpenShop)
