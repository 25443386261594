import React from 'react'
// import { Parallax } from 'react-parallax'

import { Grid, Header, Button, Icon,Segment } from 'semantic-ui-react'
import { Link, withRouter } from 'react-router-dom'
const userLang = navigator.language || navigator.userLanguage

class MerchantSec extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
    
    }
  }

  componentDidMount () {
   
  }

  render () {

    const self = this
    const p=self.props
    const pic=p.pic
    const linkP=p.linkP
    const linkM=p.linkM
    return (
      <div>
        <Grid.Row
            style={{
              paddingTop: 0,
              paddingBottom: 100,
              marginTop: 0,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              backgroundImage:
                'url('+pic+')'
            }}
          >
            <Segment
              // style={{backgroundColor:'rgba(255,255,255,.8)', padding:20}}
              style={{
                backgroundColor: 'rgba(0,0,0,0.3)',
                paddingLeft: 50,
                paddingRight: 50
              }}
            >
              <Grid
                stackable
                divided
                style={{
                  paddingTop: 150,
                  paddingBottom: 70
                }}
              >
                <Header
                  style={{
                    color: 'white',
                    // paddingLeft: 100,
                    fontSize: 60
                  }}
                >
                  {userLang === 'zh-CN' && '成为舍予商户'}
                  {userLang !== 'zh-CN' && 'Become Merchant in SHEYOU'}
                </Header>
                <Grid.Row columns={6}>
                  <Grid.Column width={4} textAlign='center'>
                    <div>
                      <>
                        <Header
                          style={{
                            color: 'white'
                          }}
                          size='large'
                        >
                          {userLang === 'zh-CN' && '长期利润'}

                          {userLang !== 'zh-CN' && 'Higher Long Term Profit'}
                        </Header>
                        <Header
                          size='small'
                          style={{
                            color: 'white'
                          }}
                        >
                          {userLang === 'zh-CN' &&
                            '租借不会产生退货，租金加卖出的钱意味着更多的利润'}
                          {userLang !== 'zh-CN' &&
                            'Renting means no sales return, and rent plus purchase produce more profit in the long term.'}
                        </Header>
                      </>
                    </div>
                  </Grid.Column>
                  <Grid.Column width={4} textAlign='center'>
                    <div>
                      <>
                        <Header
                          style={{
                            color: 'white'
                          }}
                          size='large'
                        >
                          {userLang === 'zh-CN' && '租借自由'}
                          {userLang !== 'zh-CN' && 'Freedom To Rent Out'}
                        </Header>
                        <Header
                          size='small'
                          style={{
                            color: 'white'
                          }}
                        >
                          {userLang === 'zh-CN' && '商户自定出租时间与租金'}
                          {userLang !== 'zh-CN' &&
                            'Merchants customzie their renting time and money charge'}
                        </Header>
                      </>
                    </div>
                  </Grid.Column>
                  <Grid.Column width={4} textAlign='center'>
                    <div>
                      <>
                        <Header
                          style={{
                            color: 'white'
                          }}
                          size='large'
                        >
                          {userLang === 'zh-CN' && '吸引用户'}
                          {userLang !== 'zh-CN' && 'Attract Customers'}
                        </Header>
                        <Header
                          style={{
                            color: 'white'
                          }}
                          size='small'
                        >
                          {userLang === 'zh-CN' &&
                            '租借对于用户更划算，也意味着更多笔交易'}
                          {userLang !== 'zh-CN' &&
                            'Renting is more cost effective for customers, which means more orders'}
                        </Header>
                      </>
                    </div>
                  </Grid.Column>
                  <Grid.Column width={4} textAlign='center'>
                    <div>
                      <>
                        <Header
                          style={{
                            color: 'white'
                          }}
                          size='large'
                        >
                          {userLang === 'zh-CN' && '更少成本'}
                          {userLang !== 'zh-CN' && 'Lower Production Costs'}
                        </Header>
                        <Header
                          size='small'
                          style={{
                            color: 'white'
                          }}
                        >
                          {userLang === 'zh-CN' &&
                            '租借需要生产更少的商品，每个季节也有更少的剩余'}
                          {userLang !== 'zh-CN' &&
                            'Renting requires less production, which reduces out-of-season leftovers'}
                        </Header>
                      </>
                    </div>
                  </Grid.Column>

                  <Grid.Column width={1}></Grid.Column>
                </Grid.Row>
              </Grid>
              <Grid stackable style={{ paddingBottom: 100 }}>
                <Grid.Row columns={4}>
                  <Grid.Column width={8} textAlign='center'>
                    <Link to={linkP}>
                    <Button
                      onClick={self.personalVisible}
                      size='huge'
                      circular
                      color='teal'
                      style={{
                        paddingLeft: 50,
                        paddingRight: 50,
                        width: 300
                      }}
                    >
                      {userLang === 'zh-CN' && '个人开店'}
                      {userLang !== 'zh-CN' && 'Personal Shop'}

                      <Icon
                        name='chevron right'
                        inverted
                        style={{ position: 'relative' }}
                      />
                    </Button>
                    </Link>
                  </Grid.Column>

                  <Grid.Column width={8} textAlign='center'>
                  <Link to={linkM}>
                    <Button
                      onClick={self.companyVisible}
                      size='huge'
                      circular
                      color='teal'
                      style={{
                        paddingLeft: 60,
                        paddingRight: 60,
                        width: 300
                      }}
                    >
                      {userLang === 'zh-CN' && '企业开店'}
                      {userLang !== 'zh-CN' && 'Entreprise Shop'}

                      <Icon
                        name='chevron right'
                        inverted
                        style={{ position: 'relative' }}
                      />
                    </Button>
                    </Link>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Segment>
          </Grid.Row>
        
      </div>
    )
  }
}

export default withRouter(MerchantSec)

