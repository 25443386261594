import React from 'react'
import axios from 'axios'
import {
  Grid, Icon, Divider, Form,
  Segment, Container, Button, Header,
} from 'semantic-ui-react'

import { withRouter } from 'react-router-dom'
// import allCountries from 'all-sh-countries'
import DistPicker from 'react-distpicker'

class CheckOutPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      firstName: '',
      lastName: '',
      country: '',
      state: '',
      city: '',
      phonenumber: '',
      detailAddress: '',
      identifier: '',
      token: '',
      rentOrBuy: 'buy',
      district: '',
      userInfo: [],
      shippingaddress: '',
      loading: true,
      select: 'square outline',
      selectDefault: 'check square outline',
      selectID: '',
      useAnother: false,
      save: false,
      orderid:''

    }
    this.onFirstName = this.onFirstName.bind(this)
    this.onLastName = this.onLastName.bind(this)
    // this.onCountry = this.onCountry.bind(this)
    // this.onstate = this.onstate.bind(this)
    // this.onCity = this.onCity.bind(this)
    this.Districts = this.Districts.bind(this)
    this.onPhonenumber = this.onPhonenumber.bind(this)
    this.onDetailAddress = this.onDetailAddress.bind(this)
    this.onHandleSubmit = this.onHandleSubmit.bind(this)
    this.getUserInfo = this.getUserInfo.bind(this)
    this.onSelect = this.onSelect.bind(this)
    this.useAnother = this.useAnother.bind(this)
    this.useList = this.useList.bind(this)
    this.saveAddress = this.saveAddress.bind(this)
    // this.onSelectDefault = this.onSelectDefault.bind(this)
  }
  componentDidMount() {
    
    const orderidPosition=this.props.history.location.search.search('orderid')
    const orderid = this.props.history.location.search.substring(orderidPosition+8)
 
    const identifier = localStorage.getItem('identifier')
    const self = this
    self.setState({
      rentOrBuy: 'buy',
      identifier: identifier,
      orderid:orderid,
    })
    this.getUserInfo()
    window.onload = (event) => {
      self.setState({
        loading: false
      })
    };

    window.setTimeout(() => {
      if (self.state.loading === true) {
        this.setState({
          loading: false
        })
      }

    }, 2000);
    // console.log(allCountries.GetCode('China'))

  }
  getUserInfo() {
    const self = this
    // const token=this.state.token
    const identifier = localStorage.getItem('identifier')
    const token = localStorage.getItem('token')
    const orderidPosition=this.props.history.location.search.search('orderid')
    const orderid = this.props.history.location.search.substring(orderidPosition+8)
    self.setState({
      orderid:orderid,
    })

    self.setState({
      identifier: identifier,
      token: token
    })
    axios('https://sheyou-backend.herokuapp.com/users/me', {
      method: 'get',
      headers: {
        'Authorization': 'Bearer ' + token,
      }
    })
      .then(function (res) {
        const userInfo=[res.data]
        console.log(userInfo)
        if (userInfo === null) {
          self.setState({
            userInfo: [],

          })
        } else if (userInfo.length > 0) {
          if (userInfo[0].billinginformation === null) {
            userInfo[0].billinginformation = []
          }
          if (userInfo[0].shippingaddress === null) {
            userInfo[0].shippingaddress = []
          }
          console.log(userInfo)

          self.setState({
            userInfo: userInfo,
          })
        }

        const len = userInfo[0].shippingaddress.length

        
        for (var i = 0; i < len; i++) {
          if (userInfo[0].shippingaddress[i].checkedSA === true) {
            self.setState({
              selectID: userInfo[0].shippingaddress[i].id,
              firstName: userInfo[0].shippingaddress[i].firstNameSA,
              lastName: userInfo[0].shippingaddress[i].lastNameSA,
              country: userInfo[0].shippingaddress[i].country,
              state: userInfo[0].shippingaddress[i].state,
              city: userInfo[0].shippingaddress[i].city,
              district: userInfo[0].shippingaddress[i].district,
              phonenumber: userInfo[0].shippingaddress[i].phonenumber,
              detailAddress: userInfo[0].shippingaddress[i].detailAddress,

            })
          }
        }
        

        console.log(userInfo)
        self.setState({
         
          loading: false
        })
      }).catch(function(err){
        self.props.history.push('loginfirst')
      })

    
  }

  onFirstName(event) {

    this.setState({
      firstName: event.target.value
    })

  }

  onLastName(event) {

    this.setState({
      lastName: event.target.value
    })
  }

  Districts(event) {
    console.log(event)
    this.setState({
      country: 'China',
      state: event.state,
      city: event.city,
      district: event.district
    })

  }

  onPhonenumber(event) {

    this.setState({
      phonenumber: event.target.value
    })

  }

  onDetailAddress(event) {

    this.setState({
      detailAddress: event.target.value
    })

  }

  onHandleSubmit(event) {
    event.preventDefault()
    const self = this
    const token = localStorage.getItem('token')
    const orderId = localStorage.getItem('orderId')
    var orderOrigin = []
    var userInfo = localStorage.getItem('userInfo')
    userInfo = JSON.parse(userInfo)


    const order = {
      city: self.state.city,
      country: self.state.country,
      detailAddress: self.state.detailAddress,
      district: self.state.district,
      firstName: self.state.firstName,
      identifier: self.state.identifier,
      lastName: self.state.lastName,
      phonenumber: self.state.phonenumber,
      state: self.state.state,
      rentOrBuy: self.state.rentOrBuy
    }

    // order = JSON.stringify(order)
    // localStorage.setItem('order', order)

    if (self.state.save === true) {
      var shippingaddress = this.state.userInfo[0].shippingaddress
      var len = shippingaddress.length
      len = JSON.stringify(len)
       userInfo = localStorage.getItem('userInfo')
      userInfo = JSON.parse(userInfo)
     
      const id = userInfo[0].id
      const token = localStorage.getItem('token')

      const saveData = {
        city: self.state.city,
        country: self.state.country,
        detailAddress: self.state.detailAddress,
        district: self.state.district,
        firstNameSA: self.state.firstName,
        lastNameSA: self.state.lastName,
        phonenumber: self.state.phonenumber,
        state: self.state.state,
        id: len,
        checkedSA: true

      }
      shippingaddress.forEach(function (each) {
        each.checkedSA = false
      })
      shippingaddress.push(saveData)

      axios('https://sheyou-backend.herokuapp.com/users/' + id, {
        method: 'put',
        data: {
          shippingaddress: shippingaddress
        },
        headers: {
          'Authorization': 'Bearer ' + token,
        }
      }).then(function (res) {
        if (res.status === 200) {
          console.log(res)
        } else {
          alert('something went wrong' + res)
        }

      }).catch(function (e) {
        alert('something went wrong' + e)
      })
    }
    console.log(order)

    axios('https://sheyou-backend.herokuapp.com/orders/' + orderId, {
      method: 'get',
      headers: {
        'Authorization': 'Bearer ' + token,
      }
    }).then(function (res) {
      if (res.status === 200) {
        orderOrigin = res.data.orders
        Object.assign(orderOrigin, { info: order })

        axios('https://sheyou-backend.herokuapp.com/orders/' + orderId, {
          method: 'put',
          data: {
            orders: orderOrigin
          },
          headers: {
            'Authorization': 'Bearer ' + token,
          }
        }).then(function (res) {
          if (res.status === 200) {
            console.log(res)
            self.props.history.push("/confirm?orderid="+orderId)
          } else {
            alert('something went wrong :' + res)
          }
        })
          .catch(function (err) {
            alert('something went wrong: ' + err)
          })

      } else {
        alert('something went wrong :' + res)
      }
    })
      .catch(function (err) {
        alert('something went wrong: ' + err)
      })


  }

  useAnother() {
    this.setState({
      useAnother: true
    })
  }

  useList() {
    this.setState({
      useAnother: false
    })
  }

  onSelect(event, data) {
    var id = ''
    const self = this
    console.log(event, data)
    const address = this.state.userInfo[0].shippingaddress
    if (data.id !== null || data.id !== undefined) {
      id = data.id
    }
    else {
      id = event.id
    }

    this.setState({
      selectID: id
    })

    address.forEach(function (each) {
      if (each.id === id) {
        self.setState({
          firstName: each.firstName,
          lastName: each.lastName,
          country: each.country,
          state: each.state,
          city: each.city,
          district: each.district,
          phonenumber: each.phonenumber,
          detailAddress: each.detailAddress,
        })
      }
    })

  }

  saveAddress(event, data) {
    var checked = ''
    const self = this
    if (data.checked !== null || data.checked !== undefined) {
      checked = data.checked
    }
    else {
      checked = event.checked
    }
    console.log(checked)
    if (checked === true) {
      self.setState({
        save: true
      })
    } else {
      self.setState({
        save: false
      })
    }
  }



  render() {

    const userinfo = this.state.userInfo
    console.log(userinfo)
    const self = this
    const loading = this.state.loading
    

    return <div>
      {/* <Nav /> */}

      {loading ? (
        <Grid >
          <Grid.Row></Grid.Row>
          <Grid.Row></Grid.Row>
          <Grid.Row></Grid.Row>
          <Grid.Row></Grid.Row>
          <Grid.Row></Grid.Row>
          <Grid.Row></Grid.Row>
          <Grid.Row></Grid.Row>
          <Grid.Row></Grid.Row>
          <Grid.Row></Grid.Row>


          <Grid.Row colums={2}>
            <Grid.Column width={7}></Grid.Column>
            <Grid.Column width={6}>
              <Header size='huge'>
                <Icon loading name='asterisk' size='massive' color='yellow' />
                Loading...
              </Header>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row></Grid.Row>
          <Grid.Row></Grid.Row>
          <Grid.Row></Grid.Row>
          <Grid.Row></Grid.Row>
          <Grid.Row></Grid.Row>
          <Grid.Row></Grid.Row>
          <Grid.Row></Grid.Row>
          <Grid.Row></Grid.Row>
          <Grid.Row></Grid.Row>
        </Grid>
      ) : (<>


        <div className='bg_image'>
          <br />
          <br />
          <br/>
          <br/>
          <br/>
          <Container>
            <Segment>
              <Grid container>
                <Grid.Row columns={5} style={{ marginTop: 20 }}>
                  <Grid.Column width={2}>
                  </Grid.Column>
                  <Grid.Column width={2}>
                    <Icon size='big' name='dot circle' color='orange' />
                    <Header as='h6' textAlign='left' style={{ padding: 0, marginTop: 7 }}>Adress</Header>
                  </Grid.Column>

                  <Grid.Column width={8}>
                    <Divider />
                  </Grid.Column>

                  <Grid.Column width={2}>
                    <Icon size='big' name='dot circle outline' color='grey' />
                    <Header as='h6' textAlign='left' style={{ padding: 0, marginTop: 7 }}>Confirm</Header>
                  </Grid.Column>
                  <Grid.Column width={2}>
                  </Grid.Column>
                </Grid.Row>

                <Grid.Row columns={1}>
                  <Grid.Column width={16}>
                    {userinfo.length!== 0 &&self.state.useAnother !== true && 
                    userinfo[0]!==undefined&&
                      userinfo[0].shippingaddress.length > 0 &&
                      userinfo[0].shippingaddress.map(function (each) {
                        return <Segment>
                          <Grid>
                            <Grid.Row columns={2}  >
                              <Grid.Column width={15} style={{ paddingTop: 5, paddingBotton: 0, marginTop: 0, marginBottom: 0 }}>
                                {each.checkedSA === true && <Header as='h5'>Default Shipping Address</Header>}
                                {each.checkedSA !== true && <Header as='h5'>Shipping Address</Header>}

                              </Grid.Column>

                              <Grid.Column width={1}>
                                {each.checkedSA && <>

                                  <Icon id={each.id} onClick={self.onSelect}
                                    name={(each.id !== self.state.selectID && self.state.select) ||
                                      (each.id === self.state.selectID && self.state.selectDefault)
                                    }
                                    size='large' color='orange' /></>}
                                {each.checkedSA !== true && <>

                                  <Icon id={each.id} onClick={self.onSelect}
                                    name={(each.id !== self.state.selectID && self.state.select) ||
                                      (each.id === self.state.selectID && self.state.selectDefault)
                                    }
                                    size='large' color='orange' /></>}

                              </Grid.Column>
                            </Grid.Row>

                            <Grid.Row columns={3}  >
                              <Grid.Column width={15}>
                                <Header as='h5' textAlign='left' color='grey'>
                                  {each.firstNameSA + ", " + each.lastNameSA}
                                </Header>
                              </Grid.Column>
                              <Grid.Column width={15}>
                                <Header as='h5' color='grey'> {each.city}</Header>
                              </Grid.Column>
                              <Grid.Column width={15}>
                                <Header as='h5' textAlign='left' color='grey'>{each.detailAddress}</Header>
                              </Grid.Column>
                            </Grid.Row>


                          </Grid>

                        </Segment>
                      })

                    }
                    {userinfo.length!== 0 &&self.state.useAnother !== true && 
                      userinfo[0].shippingaddress.length > 0 &&
                      <Segment onClick={self.useAnother}>
                        <Header as='h3' textAlign='center'>
                          + Use Another Address
                        </Header>
                      </Segment>
                    }
                    {userinfo.length!== 0 && (userinfo[0].shippingaddress === '' ||
                      userinfo[0].shippingaddress === null ||
                      userinfo[0].shippingaddress.length === 0) &&
                      <>
                        <Form>
                          <Form.Group widths='equal'>
                            <Form.Input fluid label='First name' placeholder='First name' onChange={this.onFirstName} />
                            <Form.Input fluid label='Last name' placeholder='Last name' onChange={this.onLastName} />

                          </Form.Group>

                        </Form>

                        <br />

                        <DistPicker onSelect={this.Districts} />

                        <br />

                        <Form>
                          <Form.Input label='Phonenumber' type='phonenumber' placeholder='Phonenumber' onChange={this.onPhonenumber} />

                          <Form.Input label='Detail Address' type='Detail Address' placeholder='Detail Address' onChange={this.onDetailAddress} />

                          <Form.Checkbox label='save as default address' onChange={this.saveAddress}/>

                        </Form>
                      </>
                    }

                    {self.state.useAnother === true && userinfo.length!== 0 &&
                      userinfo[0].shippingaddress.length > 0 &&
                      <Segment onClick={self.useList}>
                        <Header as='h3' textAlign='center'>
                          - Back To Address List
                        </Header>
                      </Segment>
                    }

                    {self.state.useAnother && <>
                      <Form>
                        <Form.Group widths='equal'>
                          <Form.Input fluid label='First name' placeholder='First name' onChange={this.onFirstName} />
                          <Form.Input fluid label='Last name' placeholder='Last name' onChange={this.onLastName} />

                        </Form.Group>

                      </Form>

                      <br />

                      <DistPicker onSelect={this.Districts} />

                      <br />

                      <Form>
                        <Form.Input label='Phonenumber' type='phonenumber' placeholder='Phonenumber' onChange={this.onPhonenumber} />

                        <Form.Input label='Detail Address' type='Detail Address' placeholder='Detail Address' onChange={this.onDetailAddress} />

                        <Form.Checkbox label='save as default address' onChange={this.saveAddress} />

                      </Form>
                    </>}



                  </Grid.Column>
                </Grid.Row>

                <Grid.Row></Grid.Row>

                <Grid.Row centered>
                  <Button color='orange' style={{ paddingLeft: 40, paddingRight: 40 }} onClick={this.onHandleSubmit}>Next</Button>
                </Grid.Row>
              </Grid>
            </Segment>
          </Container>
          <br/>
          <br />
          <br />
          <br/>
          <br/>
        </div>

      </>)}
    </div>
  }
}



export default withRouter(CheckOutPage)