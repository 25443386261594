import React from 'react'
import { Parallax } from 'react-parallax'

import { Grid, Header, Button, Icon,Segment } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
const userLang = navigator.language || navigator.userLanguage

class IntroSec extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
    
    }
  }

  componentDidMount () {
   
  }

  render () {

    const self = this
    const p=self.props
    const pic=p.pic
    return (
      <div>
        <Grid.Row
            style={{
              // paddingTop: 100,
              marginTop: 0,
              marginBottom: 0,
              paddingBottom: 0,
              backgroundColor: 'rgba(241,241,241)'
            }}
          >
            <Grid.Column
              width={9}
              style={{
                marginBottom: 0,
                backgroundImage:
                  'url('+pic+')',
                backgroundSize: 'cover'
              }}
            ></Grid.Column>
            <Grid.Column
              width={7}
              centered
              style={{
                backgroundColor: 'rgba(241,241,241)',
                marginBottom: 0,
                paddingBottom: 50,
                paddingTop: 50
              }}
            >
              <Header
                style={{
                  color: 'rgba(37,37,37)',
                  paddingTop: 50,
                  fontSize: 100
                }}
              >
                “
              </Header>
              <Header
                style={{
                  position: 'relative',
                  top: -30,
                  color: 'rgba(37,37,37)',
                  fontSize: 50,
                  textDecoration: 'underline'
                }}
              >
                {userLang === 'zh-CN' && '没有衣服穿'}
                {userLang !== 'zh-CN' && 'I Have Nothing To Wear'}
              </Header>
              <Header
                as='h3'
                style={{ color: 'rgba(37,37,37)', paddingRight: 100 }}
              >
                {userLang === 'zh-CN' &&
                  '研究表明，80%的女性平均仅将衣柜里的衣服穿五次'}
                {userLang !== 'zh-CN' &&
                  'Research shows 80% of women wear the clothing in their closets an average of 5 times.'}
              </Header>
              <Header
                as='h3'
                style={{
                  color: 'rgba(37,37,37)',
                  paddingRight: 100,
                  paddingBottom: 100
                }}
              >
                {userLang === 'zh-CN' &&
                  '租用可以减少购买服装的高额支出。既能满足想穿新衣服的愿望，又能减轻整理衣橱和洗衣服造成的负担。'}
                {userLang !== 'zh-CN' &&
                  'Renting can reduce high expenses on garments. Satisfy both the want to wear something new and release the stress of closet organizing and laundry.'}
              </Header>
            </Grid.Column>
          </Grid.Row>
        
      </div>
    )
  }
}

export default IntroSec

